import api from '@/services/gateway/api';

const state = {
  list: [],
  listLength: 0,
  equipmentList: [],
  otpOperator: '',
  document: '',
  curOperator: ''
};

const getters = {
  getList: state => state.list,
  getListLength: state => state.listLength,
  getEquipmentList: state => state.equipmentList,
  getOtpOperator: state => state.otpOperator,
  getDocument: state => state.document,
  getCurOperator: state => state.curOperator
};

const mutations = {
  setList (state, data) {
    state.list = data;
  },
  setListLength (state, data) {
    state.listLength = data;
  },
  setEquipmentList (state, data) {
    state.equipmentList = data;
  },
  setOtpOperator (state, data) {
    state.otpOperator = data;
  },
  setDocument (state, data) {
    state.document = data;
  },
  setCurOperator (state, data) {
    state.curOperator = data;
  }
};

const actions = {
  actionGetList ({ commit }, payload) {
    return api.get('camp/sapa/exchange/equipment/list', { params: payload }).then((response) => {
      commit('setList', response.data.data);
      commit('setListLength', response.data.meta.total);
    }
    ).catch(() => {});
  },
  actionSetEquipmentList ({ commit }, payload) {
    commit('setEquipmentList', payload);
  },
  actionSetOtpOperator ({ commit }, payload) {
    commit('setOtpOperator', payload);
  },
  actionSetDocument ({ commit }, payload) {
    commit('setDocument', payload);
  },
  actionSetCurOperator ({ commit }, payload) {
    commit('setCurOperator', payload);
  },
  actionReset ({ commit }) {
    commit('setOtpOperator', '');
    commit('setDocument', '');
    commit('setCurOperator', '');
    commit('setEquipmentList', []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
